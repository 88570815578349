@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* News Post Section */
.news-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'M PLUS 1', sans-serif;
}

/* Image Styling */
.news-post-image {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  display: block;
  margin: 0 auto 20px auto;
  object-fit: cover;
  border-radius: 8px; /* Added for a polished look */
}

/* Title Styling */
.news-post h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Paytone One', sans-serif;
  text-align: center; /* Center-align title on all screen sizes */
}

/* Paragraph Styling */
.news-post p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
  text-align: justify; /* Makes text easier to read on wider screens */
}

/* Main Content Styling */
.news-post div {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

/* Dark Mode Styles */
body.dark-mode .news-post h1 {
  color: #ff66ff;
}

body.dark-mode .news-post p,
body.dark-mode .news-post div {
  color: #cccccc;
}

body.dark-mode .news-post {
  background-color: #333333;
  padding: 20px;
  border-radius: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .news-post {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .news-post h1 {
    font-size: 28px; /* Smaller title font for mobile */
  }

  .news-post p,
  .news-post div {
    font-size: 16px; /* Slightly reduce font size for mobile readability */
  }
}

@media (max-width: 480px) {
  .news-post h1 {
    font-size: 24px; /* Further reduce title font for smaller devices */
  }

  .news-post-image {
    max-height: 250px; /* Limit image height on small screens */
  }
  
  .news-post p,
  .news-post div {
    font-size: 15px; /* Slightly smaller text for very small screens */
  }
}
