@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* News Section Styles */
.news-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;
    background-color: #ffffff;
}

.news-item {
    display: flex;
    flex-direction: column; /* Stacks content vertically by default */
    gap: 20px;
    border: 1px solid #dddddd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 1200px;
    margin: 0 auto;
}

.news-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.news-item img {
    width: 100%; /* Full width for better responsiveness */
    max-width: 300px; /* Limit image width */
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 auto; /* Center image on smaller screens */
}

.news-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'M PLUS 1', sans-serif;
}

.news-content .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 10px;
}

.news-content h2 {
    font-size: 24px;
    font-family: 'Paytone One', sans-serif;
    margin: 5px 0 15px 0;
    color: #333333;
}

.news-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #555555;
}

.news-link {
    text-decoration: none;
    color: inherit;
}

.news-link:hover h2 {
    color: #7d00ff;
}

/* 'Read More' Button */
.read-more-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4b0082;
    color: #ffffff;
    font-size: 16px;
    border-radius: 5px;
    text-decoration: none;
    font-family: 'Paytone One', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.read-more-container {
    text-align: center;
    margin-top: 20px;
}

.read-more-button:hover {
    background-color: #5a00bf;
}

/* Dark Mode Styles for News Section */
body.dark-mode .news-section {
    background-color: #333333;
}

body.dark-mode .news-item {
    background-color: #444444;
    border: 1px solid #555555;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

body.dark-mode .news-item:hover {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
}

body.dark-mode .news-item img {
    border: 1px solid #666666;
}

body.dark-mode .news-content .date {
    color: #bbbbbb;
}

body.dark-mode .news-content h2 {
    color: #ffffff;
}

body.dark-mode .news-content p {
    color: #dddddd;
}

body.dark-mode .news-link:hover h2 {
    color: #FFD700;
}

/* Dark Mode 'Read More' Button */
body.dark-mode .read-more-button {
    background-color: #FFD700;
    color: #000000;
}

body.dark-mode .read-more-button:hover {
    background-color: #FFC700;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .news-item {
        flex-direction: column; /* Stack image and content vertically */
        padding: 15px; /* Reduce padding on smaller screens */
    }

    .news-item img {
        max-width: 100%; /* Full width for image on smaller screens */
        margin-bottom: 10px;
    }

    .news-content h2 {
        font-size: 20px; /* Smaller title font for mobile */
    }

    .news-content p {
        font-size: 14px; /* Slightly smaller font for readability */
    }
}

@media (max-width: 480px) {
    .news-content h2 {
        font-size: 18px; /* Further reduce title font size for small screens */
    }

    .news-content p {
        font-size: 14px; /* Adjust paragraph font size */
    }

    .read-more-button {
        padding: 8px 15px; /* Smaller button padding */
        font-size: 14px; /* Adjust button font size */
    }
}

/* Footer styles remain unchanged */
.footer {
    background-color: #ff13da;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    z-index: 1;
}

.footer-content {
    padding-top: 10px;
    padding-bottom: 10px;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.social-links a img {
    width: 50px;
    transition: transform 0.3s ease;
}

.social-links a:hover img {
    transform: scale(1.2);
}

.footer p {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0;
}
