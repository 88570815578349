@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* About Us Section */
.about-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    text-align: center;
    font-family: 'M PLUS 1', sans-serif; /* Body font */
}

/* Container for Image and Text */
.about-us-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    gap: 40px;
    padding: 20px;
}

/* Image Styling */
.about-us-image img {
    width: 250px;
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Text Styling */
.about-us-text {
    max-width: 600px;
    text-align: left;
}

.about-us-text h1 {
    font-family: 'Paytone One', sans-serif; /* Title font */
    color: #ff00ff;
    font-size: 48px;
    margin-bottom: 10px;
}

.about-us-text h3 {
    font-family: 'Paytone One', sans-serif; /* Subtitle font */
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

.about-us-text p {
    color: #666666;
    font-size: 18px;
    line-height: 1.6;
    margin-top: 15px;
}

/* Team Section */
.team-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.team-section h2 {
    font-family: 'Paytone One', sans-serif; /* Section title font */
    color: #ff00ff;
    font-size: 36px;
    margin-bottom: 20px;
}

/* Director Profile Styling */
.director {
    margin-bottom: 40px;
    text-align: center;
}

.director .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.member-name {
    font-family: 'Paytone One', sans-serif; /* Name font */
    color: #ff00ff;
    font-size: 22px;
    margin-top: 10px;
}

.member-role {
    font-size: 18px;
    color: #666666;
    font-weight: 400;
    font-family: 'M PLUS 1', sans-serif; /* Body font */
}

/* Social Links Styling */
.member-social-icons {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
}

.member-social-icons a {
    font-size: 24px;
    color: #333333;
    transition: color 0.3s;
}

.member-social-icons a:hover {
    color: #ff00ff;
}

/* Grid Layout for Other Team Members */
.team-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    justify-items: center;
    width: 80%;
    margin: 0 auto;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Dark Mode Styles */
body.dark-mode .about-us-section {
    background-color: #333333;
}

body.dark-mode .about-us-text h1 {
    color: #cccccc; /* Lighter gray for readability */
}

body.dark-mode .about-us-text h3,
body.dark-mode .about-us-text p {
    color: #bbbbbb; /* Adjusted gray for better contrast */
}

body.dark-mode .team-section h2 {
    color: #ff66ff;
}

body.dark-mode .team-member .member-name {
    color: #ff66ff;
}

body.dark-mode .team-member .member-role {
    color: #bbbbbb; /* Adjusted for readability */
}

body.dark-mode .member-image img {
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.1);
}

body.dark-mode .member-social-icons a {
    color: #cccccc;
}

body.dark-mode .member-social-icons a:hover {
    color: #ff66ff;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    .about-us-container {
        flex-direction: column; /* Stack image and text vertically */
        align-items: center;
        padding: 20px;
        gap: 20px; /* Reduced gap for smaller screens */
    }

    .about-us-image img {
        width: 200px; /* Smaller image for mobile */
    }

    .about-us-text h1 {
        font-size: 36px; /* Adjusted font size for mobile */
        text-align: center;
    }

    .about-us-text h3 {
        font-size: 20px;
        text-align: center;
    }

    .about-us-text p {
        font-size: 16px;
        text-align: center;
    }

    .team-container {
        grid-template-columns: 1fr; /* Single column on mobile */
        width: 90%;
    }

    .team-section h2 {
        font-size: 28px; /* Smaller font for section title */
    }
}

@media (max-width: 480px) {
    .about-us-text h1 {
        font-size: 28px; /* Further reduce for small screens */
    }

    .about-us-text h3 {
        font-size: 18px;
    }

    .about-us-text p {
        font-size: 14px;
    }

    .team-section h2 {
        font-size: 24px;
    }

    .member-name {
        font-size: 20px;
    }

    .member-role {
        font-size: 16px;
    }
}
