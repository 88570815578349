@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* Default (light mode) styles */
body {
    background-color: #ffffff;
    color: #333333;
}

/* General styles for the Press Kit section */
.press-kit-section {
    padding: 40px 20px;
    max-width: 1140px;
    margin: 0 auto;
    background-color: #ffffff;
}

.press-kit-section h1 {
    font-family: 'Paytone One', sans-serif;
    font-size: 48px;
    color: #ff00ff;
    text-align: center;
    margin-bottom: 20px;
}

.press-kit-section p {
    font-family: 'M PLUS 1', sans-serif;
    font-size: 18px;
    color: #333333;
    line-height: 1.6;
    margin-bottom: 20px;
}

.press-kit-section h2 {
    font-family: 'Paytone One', sans-serif;
    font-size: 32px;
    color: #ff00ff;
    margin-top: 40px;
    margin-bottom: 20px;
}

/* Styles for the iframe (YouTube video) */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 20px auto;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

/* Styles for file download links */
.press-kit-section .file-list {
    margin-top: 20px;
}

.press-kit-section .file-link {
    display: block;
    font-family: 'M PLUS 1', sans-serif;
    font-size: 18px;
    color: #ff00ff;
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.3s ease;
}

.press-kit-section .file-link:hover {
    color: #c300c3;
}

/* Styles for the download button */
.download-all-button {
    display: inline-block;
    font-family: 'Paytone One', sans-serif;
    background-color: #ff00ff;
    color: white;
    font-size: 18px;
    padding: 12px 24px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.download-all-button:hover {
    background-color: #c300c3;
}

/* Dark Mode Styles */
body.dark-mode {
    background-color: #333333;
    color: #cccccc;
}

body.dark-mode .press-kit-section {
    background-color: #333333;
}

body.dark-mode .press-kit-section h1,
body.dark-mode .press-kit-section h2 {
    color: #ff66ff;
}

body.dark-mode .press-kit-section p {
    color: #cccccc;
}

body.dark-mode .press-kit-section .file-link {
    color: #ff66ff;
}

body.dark-mode .press-kit-section .file-link:hover {
    color: #ff33cc;
}

body.dark-mode .download-all-button {
    background-color: #ff66ff;
}

body.dark-mode .download-all-button:hover {
    background-color: #ff33cc;
}

body.dark-mode .press-kit-section iframe {
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.1);
}

/* Responsive styles */
@media (max-width: 1024px) {
    .press-kit-section {
        padding: 30px 15px;
    }

    .press-kit-section h1 {
        font-size: 40px;
    }

    .press-kit-section h2 {
        font-size: 28px;
    }

    .download-all-button {
        font-size: 16px;
        padding: 10px 20px;
    }
}

@media (max-width: 768px) {
    .press-kit-section h1 {
        font-size: 36px;
    }

    .press-kit-section h2 {
        font-size: 26px;
    }

    .download-all-button {
        font-size: 15px;
        padding: 8px 16px;
    }

    .video-container {
        padding-bottom: 56.25%;
    }
}

@media (max-width: 480px) {
    .press-kit-section h1 {
        font-size: 28px;
    }

    .press-kit-section h2 {
        font-size: 22px;
    }

    .download-all-button {
        font-size: 14px;
        padding: 8px 14px;
    }

    .press-kit-section p,
    .press-kit-section .file-link {
        font-size: 16px;
    }

    .video-container {
        padding-bottom: 56.25%; /* Maintains aspect ratio for small screens */
    }
}
