.skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(51, 51, 51, 0.8); /* Translucent background */
  backdrop-filter: blur(10px); /* Blur effect */
  transition: backdrop-filter 1s ease, background-color 1s ease; /* Smooth transition */
}

.decorative-element {
  pointer-events: none;
}


.skeleton-container.loaded {
  background-color: transparent; /* Clear background when loading is done */
  backdrop-filter: none; /* Remove blur effect */
  display: none; /* Fully remove it from the layout */
}

.heart {
  fill: red;
  width: 100px;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

/* Fade-in animation */
.content {
  opacity: 0;
  animation: fadeIn 3.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Reduce animations for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .heart {
    animation: none;
  }

  .content {
    animation: none;
    opacity: 1; /* Immediately visible */
  }
}

@media (max-width: 768px) {
  .skeleton-container {
    min-height: calc(100vh - 50px); /* Account for browser UI adjustments */
  }
}
