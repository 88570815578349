/* Import Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Noto+Sans+JP:wght@400;700&family=Dela+Gothic+One&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  height: auto; /* Allow height to grow with content */
  overflow-x: hidden; /* Ensure no horizontal scrollbars */
  scroll-behavior: smooth; /* Smooth scrolling for better UX */
}

body {
  font-family: 'MPLUS1', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  transition: background-color 0.9s ease, color 0.9s ease;
  -webkit-overflow-scrolling: touch; /* Fix scroll locking on touch devices */
}

.decorative-element {
  pointer-events: none;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto; /* Allow height to grow with content */
  overflow-x: hidden; /* Disable horizontal scrolling in .App */
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

footer {
  background-color: #ff13da;
  color: white;
  text-align: center;
  padding: 20px 0;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 30px;
  transition: background-color 0.9s ease, color 0.9s ease;
}

/* Typography styles */
h1 {
  font-size: 36px;
  font-family: 'Paytone One', sans-serif;
  color: #FF13DA;
}

h2 {
  font-size: 36px;
  font-family: 'Paytone One', sans-serif;
  color: #FC7E86;
}

h3 {
  font-size: 24px;
  font-family: 'Paytone One', sans-serif;
  color: black;
}

button,
.nav-item.button-text {
  font-size: 18px;
  font-family: 'Paytone One', sans-serif;
}

p, .main-text {
  font-size: 18px;
  font-family: 'MPLUS1', sans-serif;
}

/* Header styles */
.header {
  width: 100%;
  background-color: #7d00ff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo {
  font-family: "Paytone One", "Noto Sans JP", "Dela Gothic One", sans-serif;
  font-size: 34px;
  color: white;
  margin-right: 50px;
  transition: color 0.9s ease;
}

.logo-image {
  width: 200px;
  height: auto;
  margin-left: -10px;
  transition: transform 0.9s ease;
}

.menu-container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
}

.nav {
  display: flex;
  align-items: center; /* Ensure vertical alignment within the parent container */
  margin: 0; /* Remove any extra margin */
  padding: 0; /* Reset padding to ensure clean alignment */
  position: relative; /* Allows precise vertical adjustment */
  gap: 7px; /* Adjust spacing between items */
  flex-wrap: nowrap; /* Ensure items don't wrap to the next line */
}

.nav-item {
  font-family: "Dela Gothic One", "Paytone One", "Noto Sans JP", sans-serif;
  font-size: 28px;
  color: white;
  text-decoration: none;
  padding: 0 15px; /* Horizontal spacing remains unchanged */
  transition: color 0.9s ease;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-item:hover {
  color: #ffd700;
}

/* Icon group */
.icon-group {
  display: flex;
  align-items: center;
  gap: 15px; /* Reduced the gap to ensure fitting on small screens */
  margin-left: auto;
  position: relative;
  top: 0; /* Remove any negative top offset */
}

/* Language toggle */
.language-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-icon {
  width: auto; /* Automatically calculate the width based on height */
  height: 24px; /* Set a consistent height */
  object-fit: contain; /* Ensures the icon scales properly within its container */
  display: block; /* Prevent any inline layout issues */
  transition: transform 0.9s ease, opacity 0.9s ease;
}

/* Dark Mode Toggle */
.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sun-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #FFD700;
  position: relative;
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.8);
}

.sun-icon::before,
.sun-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 3px;
  background-color: #FFD700;
  transform-origin: center;
}

.sun-icon::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.sun-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.moon-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #FFF;
  position: relative;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
}

.moon-icon::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #7d00ff;
}

.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0; /* Remove extra margins that could push it off-screen */
  position: static; /* Ensure it doesn't shift oddly on small screens */
}

.hamburger-menu svg {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
  margin-top: 3px; /* Move the menu down by 3px */
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

.nav.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  background-color: #7d00ff;
  padding: 20px;
  z-index: 3;
  overflow-y: auto; /* Ensure scrolling within the menu */
  overflow-x: hidden;
}

/* Light/Dark mode styles */
body.dark-mode {
  background-color: #444444;
  color: #f0f0f0;
  transition: background-color 0.9s ease, color 0.9s ease;
}

body.dark-mode .header {
  background-color: #7d00ff;
}

body.dark-mode a {
  color: #FFD700;
}

body.dark-mode .nav-item {
  color: #FFD700;
}

.footer {
  width: 100%;
  background-color: #ff13da;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

.dot-pattern {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 270px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.social-links {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.social-links a .social-icon {
  color: white;
  transition: color 0.2s ease, transform 0.3s ease;
}

.social-links a:hover .social-icon {
  transform: scale(1.2);
}

.social-links a .social-icon path {
  fill: white;
}

.footer p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .dot-pattern, .footer {
    margin: 0;
    padding: 0;
  }

  .nav {
    display: none;
  }
  .nav.open {
    display: flex;
  }
  .hamburger-menu {
    display: block;
  }
}

@media (min-width: 1025px) {
  .hamburger-menu {
    display: none;
  }
}

@media (max-width: 600px) {
  .icon-group {
    gap: 7px; /* Even smaller gap for narrow screens */
  }

  .header {
    padding: 10px;
    justify-content: space-between;
  }

    /* Reduce logo image size on very small screens to free up space */
    .logo-image {
      width: 150px; /* Reduce from 200px to 150px */
      margin-left: 0; /* Remove negative margin if any */
    }

    /* Slightly reduce icon sizes to ensure they all fit horizontally */
    .language-icon,
    .dark-mode-icon {
      width: 24px !important;
      height: 24px !important;
    }

  .nav {
    display: none;
  }

  .nav.open {
    display: flex;
  }
}

/* Desktop only: show the dropdown when active */
@media (min-width: 769px) {
  .games-dropdown.active .dropdown-menu {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 1025px) {
  .icon-group {
    display: flex;
    align-items: center;
    gap: 15px; 
    margin-left: auto;
    position: relative;
    top: 0;
  }
}

.language-toggle {
  margin-left: 10px;
}

.dark-mode-toggle {
  margin-left: 10px;
}

.games-dropdown {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: var(--dropdown-bg);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 15px 0;
  z-index: 10;
  width: 150px;
  display: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 0;
  transform: translateY(-10px);
}

.games-dropdown.active .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu li a,
.dropdown-menu li span {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
}

.dropdown-menu li a:hover,
.dropdown-menu li span:hover {
  background-color: var(--hover-bg);
  color: var(--hover-text-color);
  border-radius: 4px;
}

.dropdown-menu li span {
  cursor: pointer;
  display: block;
}

body.dark-mode {
  --dropdown-bg: #A020F0;
  --primary-color: #FFD700;
  --hover-bg: #A020F0;
  --hover-text-color: #FFD700;
}

body:not(.dark-mode) {
  --dropdown-bg: #A020F0;
  --primary-color: #fff;
  --hover-bg: #A020F0;
  --hover-text-color: #fff;
}

@media (max-width: 768px) {
  .dropdown-menu {
    display: none !important;
  }

  .games-dropdown.active .dropdown-menu {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }

  .nav-item.games-dropdown {
    pointer-events: auto;
    cursor: pointer;
  }
}
