@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* Contact Us Section */

html, body {
    height: 100%;
    margin: 0;
}

/* Wrapper for content to fill the height */
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure it fills the full height of the viewport */
}

.decorative-element {
  pointer-events: none;
}

/* Main content should expand to fill the remaining space */
.content {
    flex-grow: 1;
}

/* Footer positioning */
.footer {
    background-color: #ff13da;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
}

.contact-us-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
}

.contact-us-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-us-image {
    margin-right: 50px;
    flex-shrink: 0; /* Prevent the image from shrinking */
}

.contact-us-image img {
    width: 100%; 
    max-width: 400px; /* Larger maximum width for the image */
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-us-text h1 {
    font-family: 'Paytone One', sans-serif; /* Title font */
    color: #ff00ff; 
    font-size: 48px;
}

.contact-us-text h3 {
    font-family: 'Paytone One', sans-serif; /* Subtitle font */
    font-size: 24px;
    margin-top: 10px;
    color: #333333;
}

.contact-us-text p {
    font-family: 'M PLUS 1', sans-serif; /* Body font */
    font-size: 18px;
    line-height: 1.6;
    margin-top: 15px;
    color: #666;
}

/* Dark Mode Styles */
body.dark-mode .contact-us-section {
    background-color: #333333; /* Dark background for the section */
}

body.dark-mode .contact-us-text h1 {
    color: #ff66ff; /* Lighter purple for the header */
}

body.dark-mode .contact-us-text h3 {
    color: #dddddd; /* Lighter grey for better readability */
}

body.dark-mode .contact-us-text p {
    color: #cccccc; /* Light grey for better readability in dark mode */
}

body.dark-mode .contact-us-image img {
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.1); /* Light shadow for dark mode */
}

/* Responsive styles for tablets and mobile */
@media (max-width: 1024px) {
  .contact-us-container {
    padding: 30px;
    gap: 30px;
  }

  .contact-us-text h1 {
    font-size: 40px; /* Slightly smaller for tablets */
  }

  .contact-us-text h3 {
    font-size: 22px;
  }

  .contact-us-text p {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .contact-us-container {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;
    padding: 20px;
    gap: 20px; /* Reduced gap for tablets and smaller screens */
  }

  .contact-us-image {
    margin-right: 0;
    margin-bottom: 20px; /* Add spacing between image and text */
  }

  .contact-us-image img {
    max-width: 300px; /* Reduced max width for smaller screens */
  }

  .contact-us-text h1 {
    font-size: 36px;
    text-align: center;
  }

  .contact-us-text h3 {
    font-size: 20px;
    text-align: center;
  }

  .contact-us-text p {
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .contact-us-section {
    padding: 20px 10px; /* Reduce padding on mobile */
  }

  .contact-us-image img {
    max-width: 250px; /* Smaller max width for compact screens */
  }

  .contact-us-text h1 {
    font-size: 28px; /* Smaller font for very small screens */
  }

  .contact-us-text h3 {
    font-size: 18px;
  }

  .contact-us-text p {
    font-size: 15px;
  }
}
