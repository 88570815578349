/* Import Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

/* Policy Section */
.policy-section {
  padding: 40px 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
}

.policy-container {
  max-width: 1200px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: column;
}

@media (min-width: 768px) {
  .policy-container {
    flex-direction: row;
  }
}

.policy-image {
  flex-shrink: 0;
  margin-bottom: 20px;
}

.policy-image img {
  border-radius: 15px;
  width: 100%;
  max-width: 300px;
  height: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.policy-text {
  max-width: 100%;
  margin-left: 0;
}

@media (min-width: 768px) {
  .policy-text {
    margin-left: 40px;
  }
}

.policy-text h1 {
  font-family: 'Paytone One', sans-serif;
  font-size: 32px;
  color: #ff00ff;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .policy-text h1 {
    font-size: 48px;
    margin-top: 30px;
  }
}

.policy-text h3 {
  font-family: 'Paytone One', sans-serif;
  font-size: 20px;
  margin-top: 10px;
  color: #333;
  font-weight: 400;
}

@media (min-width: 768px) {
  .policy-text h3 {
    font-size: 24px;
  }
}

.policy-content {
  margin-top: 20px;
  font-family: 'M PLUS 1', sans-serif;
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  font-weight: 400;
}

@media (min-width: 768px) {
  .policy-content {
    font-size: 18px;
  }
}

/* Sub-menu (New Navigation Menu for Sections) */
.policy-menu {
  margin-bottom: 20px;
}

.policy-menu ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px; /* Ensure proper spacing between bubbles */
  flex-wrap: wrap;
  justify-content: center;
}

.policy-menu li {
  margin-right: 0;
}

/* Policy Section Titles - Clickable Bubbles */
.policy-menu button {
  background-color: #7d00ff;
  color: white;
  font-size: 16px;
  font-family: 'Paytone One', sans-serif;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 20px;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.policy-menu button.jp {
  padding: 10px 20px; /* Extra padding for JP bubbles */
  font-size: 18px; /* Slightly larger font size for JP */
}

.policy-menu button:hover,
.policy-menu li.active button {
  background-color: #5800b8;
  transform: scale(1.1);
}

/* Dark Mode Styles */
body.dark-mode .policy-section {
  background-color: #333333;
}

body.dark-mode .policy-text h1 {
  color: #ff66ff;
}

body.dark-mode .policy-text h3 {
  color: #cccccc;
}

body.dark-mode .policy-content {
  color: #cccccc;
}

body.dark-mode .policy-image img {
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.1);
}

body.dark-mode .policy-menu button {
  background-color: #ff66ff;
  color: black;
  font-family: 'Paytone One', sans-serif;
}

body.dark-mode .policy-menu button:hover,
body.dark-mode .policy-menu li.active button {
  background-color: #cc00cc;
  color: white;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .policy-text h1 {
    font-size: 28px;
  }

  .policy-text h3 {
    font-size: 18px;
  }

  .policy-content {
    font-size: 15px;
  }

  .policy-menu button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .policy-menu button.jp {
    font-size: 16px; /* Adjust for JP bubbles */
    padding: 8px 15px;
  }

  .policy-image img {
    max-width: 250px;
  }
}
