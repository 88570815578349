/* Games Section Styles */
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

.games-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'M PLUS 1', sans-serif; /* Body font */
}
  
.game-card {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
    border-bottom: none;
    padding-bottom: 20px;
    width: 100%;
}
  
.game-image img {
    width: 200px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
  
.game-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 600px;
}
  
.game-logo {
    width: 250px;
    height: auto;
    margin-bottom: 10px;
    font-family: 'Paytone One', sans-serif; /* Heading font */
}
  
.release-date {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    margin: 0;
    font-family: 'Paytone One', sans-serif; /* Heading font */
}
  
.description {
    font-size: 16px;
    color: #666;
    margin: 10px 0;
}
  
.game-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
  
.btn {
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    font-family: 'Paytone One', sans-serif; /* Button font */
}
  
/* Filled Steam Button */
.btn-steam {
    background-color: #a403ff;
    color: white;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
  
/* Outline Learn More Button */
.btn-learn {
    background-color: transparent;
    color: #a403ff;
    border: 2px solid #a403ff;
    transition: background-color 0.3s, color 0.3s;
}
  
/* Hover Effects */
.btn-steam:hover {
    background-color: #8903cc;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.btn-learn:hover {
    background-color: #a403ff;
    color: white;
}

/* Dark Mode Adjustments */
body.dark-mode .release-date,
body.dark-mode .description {
    color: #cccccc;
}

body.dark-mode .btn-steam {
    background-color: #9a55ff;
    color: white;
}

body.dark-mode .btn-learn {
    color: #9a55ff;
    border-color: #9a55ff;
}

body.dark-mode .btn-steam:hover {
    background-color: #920edb;
}

body.dark-mode .btn-learn:hover {
    background-color: #9a55ff;
    color: white;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .games-section {
        padding: 20px 10px; /* Reduce padding on mobile */
    }

    .game-card {
        flex-direction: column; /* Stack image and content vertically */
        align-items: center;
        text-align: center; /* Center-align text for mobile */
    }

    .game-image img {
        width: 100%; /* Full width for better responsiveness */
        max-width: 300px; /* Limit max width on mobile */
        margin-bottom: 15px; /* Add space below the image */
    }

    .game-content {
        max-width: 100%; /* Full width for content */
    }

    .game-logo {
        width: 100%; /* Full width for game logo */
        max-width: 200px; /* Adjust max width on mobile */
        margin: 0 auto 15px auto; /* Center the logo */
    }

    .release-date {
        font-size: 16px; /* Slightly smaller font */
    }

    .description {
        font-size: 14px; /* Reduce font size for readability */
    }

    .game-buttons {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px;
    }

    .btn {
        font-size: 16px; /* Slightly smaller font size */
        padding: 10px 20px; /* Adjust padding */
        width: 100%; /* Full width for buttons on mobile */
        max-width: 200px; /* Limit max width */
        margin: 0 auto; /* Center buttons */
    }
}
