/* General Twitter section styles */
.twitter-section {
    display: flex;
    justify-content: center; 
    align-items: center;     
    flex-direction: column;
    padding: 40px 0;         
    background-color: #FFFFFF; 
}
  
.twitter-feed-wrapper {
    max-width: 800px;       
    width: 100%;
}
  
.twitter-feed h2 {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333; 
}
  
.twitter-feed iframe {
    width: 100%;
    max-width: 600px;  
    height: auto;      
}
  
.twitter-follow {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Dark Mode Styles */
body.dark-mode .twitter-section {
    background-color: #333333; /* Dark background */
}

body.dark-mode .twitter-feed h2 {
    color: #f7f7f7; /* Light color for text in dark mode */
}

body.dark-mode .twitter-feed-wrapper {
    background-color: #444; 
}

body.dark-mode .twitter-follow a {
    color: #f7f7f7; /* Change the color of the follow button link in dark mode */
}

.twitter-section {
    max-width: 100%; /* Ensure it doesn't overflow */
    margin: 0 auto;
    padding: 10px; /* Add some padding for better spacing */
  }
  
  .twitter-feed-wrapper {
    width: 100%; /* Full width on mobile */
    overflow: hidden; /* Prevent overflow issues */
  }
  
  .twitter-follow {
    text-align: center; /* Center the follow button */
    margin-top: 10px;
  }

.twitter-error-link {
    color: yellow; /* Match the link color */
    font-size: 16px;
    text-decoration: underline;
    display: block;
    text-align: center;
    margin-top: 20px;
    font-family: 'Noto Sans JP', sans-serif;
  }
  
  /* Dark Mode Styles */
  body.dark-mode .twitter-error-link {
    color: #FFD700; 
  }
  
