/* Landing Section Styles */
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=MPLUS+1p:wght@400;700&display=swap');

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.landing-section {
    position: relative;
    width: 100vw; 
    height: 100vh; 
    overflow: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Slideshow container */
.slideshow-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}

/* Slide */
.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    z-index: 0;
}

/* Active slide styling */
.slide.active {
    opacity: 1;
    z-index: 1;
    transform: translateX(0);
}

/* Inactive slide styling */
.slide.inactive {
    transform: translateX(100%);
}

/* Previous slide styling */
.slide.prev {
    transform: translateX(-100%);
}

/* Slide image */
.slide-image, .slide-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* Video specific styling */
.slide-video {
    background-color: black;
    display: block;
    object-fit: cover;
    object-position: center;
}

.slide-video[controls] {
    display: none;
}

.slide::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80%; 
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    z-index: 1;
}

/* Slide content */
.slide-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2;
    max-width: 90%;
}

/* Ensuring title is Paytone One */
.slide-content h1 {
    font-family: 'Paytone One', sans-serif;
    font-size: 48px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.slide-content p {
    font-family: 'MPLUS 1p', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #7d00ff;
    color: white;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    font-family: 'Paytone One', sans-serif;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #5800b8;
}

/* Arrows for manual control */
.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 32px;
    z-index: 2;
    transition: transform 0.2s ease;
}

.prev:hover, .next:hover {
    transform: scale(1.2);
}

.prev {
    left: 100px;
}

.next {
    right: 100px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .landing-section {
      height: 500px;
    }

    .slide-content h1 {
      font-size: 32px;
    }

    .slide-content p {
      font-size: 14px;
    }

    .cta-button {
      font-size: 16px;
      padding: 12px 24px;
    }

    .prev, .next {
      padding: 8px;
      font-size: 24px;
    }

    .prev {
      left: 50px;
    }

    .next {
      right: 50px;
    }
}
