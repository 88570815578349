/* NewsPageStyles.css */
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=M+PLUS+1:wght@400;700&display=swap');

.news-page {
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'M PLUS 1', sans-serif; /* Body font */
}

.news-title {
    font-family: 'Paytone One', sans-serif; /* Heading font */
    font-size: 36px;
    color: #ff00ff;
    text-align: center;
    margin-bottom: 20px;
}

.news-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.news-item {
    display: flex;
    gap: 20px;
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    transition: background-color 0.3s;
}

.news-item:hover {
    background-color: #f9f9f9;
}

.news-image {
    width: 300px;
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
}

.news-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.news-date {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.news-heading {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    font-family: 'Paytone One', sans-serif; /* Heading font */
}

.news-summary {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
    .news-page {
        padding: 30px 10px; /* Reduced padding for smaller screens */
    }

    .news-title {
        font-size: 28px; /* Smaller font for title */
    }

    .news-item {
        flex-direction: column; /* Stack items vertically */
        gap: 15px;
        padding-bottom: 15px;
    }

    .news-image {
        width: 100%; /* Full width on mobile */
        height: auto; /* Adjust height proportionally */
    }

    .news-heading {
        font-size: 20px; /* Smaller heading font */
    }

    .news-summary {
        font-size: 14px; /* Adjust summary font size */
    }
}

@media (max-width: 480px) {
    .news-title {
        font-size: 24px; /* Further reduce title font size on very small screens */
    }

    .news-heading {
        font-size: 18px;
    }

    .news-summary {
        font-size: 13px; /* Smaller summary font for compact screens */
        line-height: 1.4;
    }

    .news-date {
        font-size: 12px; /* Adjust date font size */
    }
}
